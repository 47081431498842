import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

import "../scss/main.scss";

const TemplateWrapper = ({ children }) => {

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="format-detection" content="telephone=no" />

        <meta name="author" content="The Skyline Agency"></meta>
        <title>Sublime Contact Us</title>


        <meta name="description" content="Sublime Contact Us" />
        <link rel="canonical" href={`${withPrefix("/")}`} />

      </Helmet>

      <div className="body-overflow">
        <main id="main-content">
          {children}
        </main>
      </div>
    </div>
  );
};

export default TemplateWrapper;