import React, { useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../img/logo.png';
// eslint-disable-next-line
export const ContactPageTemplate = () => {

	const RECAPTCHA_KEY = "6LfjneIlAAAAAPhrsvIZxZdYbu7KHV6eX8c7SsoO";

	const [formError, setFormError] = useState(false);

	const [fileNames, setFileNames] = useState([]);

	const [purpose, setPurpose] = useState("");

	const [self, setSelf] = useState(false);

	const [sec, setSec] = useState(false);

	const handleSubmit = e => {
		e.preventDefault()
		const form = e.target
		const formData = new FormData(form);

		fetch('/', {
			method: 'POST',
			body: formData,
		})
			.catch(error => {
				setFormError(true);
				alert(error)
			});
		window.location.href = "https://www.sublimefinancial.com/intake-form-completed";
	}

	const changeHandler = (e, index) => {
		if (e.target.files.length > 0) {
			let filename = e.target.files[0].name;
			let tempArr = [...fileNames];
			tempArr[index] = filename;
			setFileNames(tempArr)
		}
	}

	const purposeChange = (value) => {
		setPurpose(value);
	}


	return (

		<div className="container" style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
			<img src={logo} alt="Logo" />
			{!formError ?
				<div className="columns is-justify-content-center">
					<div className="column">
						<form onSubmit={handleSubmit} name="loan-form" id="loan-form" method="POST" encType="multipart/form-data" data-netlify="true" className="box p-5">
							<input type="hidden" name="form-name" value="loan-form" />
							<label className="is-block mb-4">
								<span className="is-block mb-2">Borrower</span>
								<input
									name="borrower_name"
									type="text"
									className="input"
									placeholder="Borrower Name"
									required
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Loan Purpose</span>
								<div className="select">
									<select onChange={purposeChange}>
										<option value="" selected disabled hidden>Please choose one option</option>
										<option value="re-finance">Re-Finance</option>
										<option value="purchase">Purchase</option>
										<option value="no-cashout">No Cashout</option>
										<option value="cashout">Cashout</option>
									</select>
								</div>
							</label>

							<label class="checkbox">
								<input checked={self} onChange={() => setSelf(!self)} name="self_employed" type="checkbox" />
								&nbsp;Check this box if you are currently self-employed
							</label>
							<br />
							<br />

							<label className="is-block mb-4">
								<span className="is-block mb-2">Loan Officer</span>
								<input
									name="lo"
									type="text"
									className="input"
									required
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Address</span>
								<input
									name="address"
									type="text"
									className="input"
									placeholder="22 Example Street"
									required
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Loan Program</span>
								<input
									name="loan_program"
									type="text"
									className="input"
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Lender</span>
								<input
									name="lender_name"
									type="text"
									className="input"
									placeholder="Lender Name"
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Loan Number</span>
								<input
									name="loan_number"
									type="text"
									className="input"
									placeholder="XXXXXXXXX"
									required
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Title Company</span>
								<input
									name="title_company"
									type="text"
									className="input"
									placeholder="Company Name"
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Contact</span>
								<input
									name="title_company_contact"
									type="text"
									className="input"
									placeholder="555-555-5555"
								/>
							</label>

							<label className="is-block mb-4">
								<span className="is-block mb-2">Email address</span>
								<input
									required
									name="email"
									type="email"
									className="input"
									placeholder="joe.bloggs@example.com"
								/>
							</label>

							<div className="container">
								<hr />
								<h2 className="title is-5">Last two years tax returns</h2>

								<p>Previous Tax Return #1</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 0)} className="file-input" type="file" name="tax_return_1" id="tax_return_1" required />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[0] != null ? fileNames[0] : "Upload File"}
										</span>
									</label>
								</div>

								<br />
								<p>Previous Tax Return #2</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 1)} className="file-input" type="file" name="tax_return_2" id="tax_return_2" required />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[1] != null ? fileNames[1] : "Upload File"}
										</span>
									</label>
								</div>
								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Last two years W2’s</h2>
								<span>*Not required if you are self-employed.</span>

								<p>Previous W2 #1</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 2)} className="file-input" type="file" name="w2_1" id="w2_1" required={!self} />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[2] != null ? fileNames[2] : "Upload File"}
										</span>
									</label>
								</div>

								<br />
								<p>Previous W2 #2</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 3)} className="file-input" type="file" name="w2_2" id="w2_2" required={!self} />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[3] != null ? fileNames[3] : "Upload File"}
										</span>
									</label>
								</div>
								<hr />
							</div>


							<div className="container">
								<h2 className="title is-5">Most recent 30 days check stub</h2>
								<span>*Not required if you are self-employed.</span>

								<p>Stub #1</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 4)} className="file-input" type="file" name="stub_1" id="stub_1" required={!self} />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[4] != null ? fileNames[4] : "Upload File"}
										</span>
									</label>
								</div>
								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Two most recent bank statements</h2>

								<p>Statement #1</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 5)} className="file-input" type="file" name="statement_1" id="statement_1" required />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[5] != null ? fileNames[5] : "Upload File"}
										</span>
									</label>
								</div>

								<br />
								<p>Statement #2</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 6)} className="file-input" type="file" name="statement_2" id="statement_2" required />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[6] != null ? fileNames[6] : "Upload File"}
										</span>
									</label>
								</div>
								<hr />
							</div>

							<label class="checkbox">
								<input checked={sec} onChange={() => setSec(!sec)} name="receiving_security" type="checkbox" />
								&nbsp;Check this box if you are currently receiving security.
							</label>
							<br />
							<br />

							{sec ?
								<div className="container">
									<h2 className="title is-5">Copy of the Award Letter, if receiving social security</h2>

									<p>Award Letter</p>
									<div className="file has-name">

										<label className="file-label">

											<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 7)} className="file-input" type="file" name="award_1" id="award_1" required={sec}/>
											<span className="file-cta">
												<span className="file-icon">
													<i className="fas fa-upload"></i>
												</span>
												<span className="file-label">
													Choose a file…
												</span>
											</span>
											<span className="file-name">
												{fileNames[7] != null ? fileNames[7] : "Upload File"}
											</span>
										</label>
									</div>
									<hr />
								</div>
								: ""}

							<div className="container">
								<h2 className="title is-5">Copy of driver’s license or passport and social security card (unexpired)</h2>

								<p>Passport/Drivers License</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 8)} className="file-input" type="file" name="id_1" id="id_1" required />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[8] != null ? fileNames[8] : "Upload File"}
										</span>
									</label>
								</div>

								<br />
								<p>Social Security Card</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 9)} className="file-input" type="file" name="ssn" id="ssn" required />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[9] != null ? fileNames[9] : "Upload File"}
										</span>
									</label>
								</div>
								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Insurance Information</h2>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Insurance Name</span>
									<input
										required
										name="insurance_name"
										type="text"
										className="input"
										placeholder="Company Name"
									/>
								</label>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Insurance Phone Number</span>
									<input
										required
										name="insurance_phone"
										type="phone"
										className="input"
										placeholder="555-555-5555"
									/>
								</label>
								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Survey</h2>

								<p>Survey</p>
								<div className="file has-name">

									<label className="file-label">

										<input accept=".jpg,.doc,.docx,.pdf,.png,.csv,.xlsx" onChange={e => changeHandler(e, 10)} className="file-input" type="file" name="survey" id="survey" />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose a file…
											</span>
										</span>
										<span className="file-name">
											{fileNames[10] != null ? fileNames[10] : "Upload File"}
										</span>
									</label>
								</div>
								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Landlord Information (if renting, optional)</h2>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Landlord Name</span>
									<input
										required
										name="landlord_name"
										type="text"
										className="input"
										placeholder="Full Name"
									/>
								</label>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Landlord Phone Number</span>
									<input
										required
										name="landlord_phone"
										type="phone"
										className="input"
										placeholder="555-555-5555"
									/>
								</label>
								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Mortgage Payoff (Required if Re-Financing)</h2>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Mortgage Name</span>
									<input
										name="mortgage_name"
										type="text"
										className="input"
										placeholder="Full Name"
										required={purpose == "re-finance"}
									/>
								</label>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Mortgage Phone Number</span>
									<input
										required={purpose == "re-finance"}
										name="mortgate_phone"
										type="phone"
										className="input"
										placeholder="555-555-5555"
									/>
								</label>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Mortgage Account Number</span>
									<input
										required={purpose == "re-finance"}
										name="mortgate_account"
										type="text"
										className="input"
									/>
								</label>
								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Homeowner’s association (If Re-Financing)</h2>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Name</span>
									<input
										required={purpose == "re-finance"}
										name="homeowners_name"
										type="text"
										className="input"
										placeholder="Full Name"
									/>
								</label>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Phone Number</span>
									<input
										required={purpose == "re-finance"}
										name="homeowners_phone"
										type="phone"
										className="input"
										placeholder="555-555-5555"
									/>
								</label>


								<hr />
							</div>

							<div className="container">
								<h2 className="title is-5">Copy of other assets (401k, 403b, Retirement, Life Policies, Stocks, IRA, etc)</h2>

								<p>Asset Documents</p>
								<div className="file has-name">

									<label className="file-label">

										<input multiple onChange={e => changeHandler(e, 11)} className="file-input" type="file" name="assets" id="assets" />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">
												Choose file(s)…
											</span>
										</span>
										<span className="file-name">
											{fileNames[11] != null ? fileNames[10] : "Upload File(s)"}
										</span>
									</label>
								</div>
								<hr />
							</div>

							{!self ? 
							<div className="container">
								<h2 className="title is-5">Current Employer</h2>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Full Name</span>
									<input
										name="employer_name"
										type="text"
										className="input"
										placeholder="Full Name"
									/>
								</label>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Employer Phone Number</span>
									<input
										name="employer_phone"
										type="phone"
										className="input"
										placeholder="555-555-5555"
									/>
								</label>

								<label className="is-block mb-4">
									<span className="is-block mb-2">Employer Email Address</span>
									<input
										name="employer_phone"
										type="phone"
										className="input"
										placeholder="555-555-5555"
									/>
								</label>
								<hr />
							</div>
							:""}

							<br />

							<div className="mb-4">
								<ReCAPTCHA
									sitekey={RECAPTCHA_KEY}
									size="normal"
									id="recaptcha-google"
								/>
								<br />
								<button type="submit" className="button is-link px-4">Submit</button>
							</div>
						</form>
					</div>
				</div>
				: <p>{formError}</p>}
		</div>

	);
};

const ContactPage = () => {

	return (
		<Layout>
			<ContactPageTemplate />
		</Layout>
	);
};

export default ContactPage;